import queryString from 'query-string';
import React from "react";
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';

const Index = () => {
  // if user has bookmarked a softquote URL with a better start query string parameter, redirect them to the debt con page when the better start promo is not running
  const queryParams = typeof window !== 'undefined' && queryString.parse(window.location.search);
  if (queryParams && queryParams.subType === `betterStart`) {
    const plDebtConAuUrl = getUrlFromEnv('pl-debt-con-au-url');
    if (plDebtConAuUrl) window.location = plDebtConAuUrl;
  }
  if (typeof window !== `undefined`){
    window.location = '/personal-loans'
  } else {
    // Only required for yarn build
    return <div />
  }
};
export default Index;
